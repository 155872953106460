// useStreamingLogic.js
import { useState, useCallback, useRef } from 'react';
import { URL } from '../../../Helpers';

const RETRY_DELAYS = [1000, 2000, 4000];
const MAX_RETRIES = 3;

export const useStreamingLogic = (user, sessionDate, tempDirRef, selectedModule) => {
    const [streamingText, setStreamingText] = useState('');
    const [isStreaming, setIsStreaming] = useState(false);
    const [streamError, setStreamError] = useState(null);
    
    const abortControllerRef = useRef(null);
    const retryCountRef = useRef(0);
    const retryTimeoutRef = useRef(null);

    const cleanup = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
        }
        if (retryTimeoutRef.current) {
            clearTimeout(retryTimeoutRef.current);
            retryTimeoutRef.current = null;
        }
        retryCountRef.current = 0;
        setStreamError(null);
    }, []);

    const startStreaming = useCallback(async (transcription, retryAttempt = 0) => {
        if (!transcription) {
            console.error('No transcription provided');
            return;
        }

        // Clear any existing streams and errors
        cleanup();
        setStreamError(null);

        // Start new stream
        setIsStreaming(true);
        if (retryAttempt === 0) {
            setStreamingText(''); // Only clear text on first attempt
        }

        // Create new AbortController
        abortControllerRef.current = new AbortController();

        try {
            const response = await fetch(
                `${URL}/stream_question/${sessionDate}/${tempDirRef.current}?module_id=${selectedModule}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.accessToken}`,
                        'Accept': 'text/event-stream',
                        'Cache-Control': 'no-cache',
                        'Connection': 'keep-alive'
                    },
                    body: JSON.stringify({ transcription }),
                    signal: abortControllerRef.current.signal
                }
            );

            if (!response.ok) {
                throw new Error(`Request failed: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let accumulatedText = retryAttempt > 0 ? streamingText : '';

            try {
                while (true) {
                    const { value, done } = await reader.read();
                    if (done) break;

                    const chunk = decoder.decode(value, { stream: true });
                    const lines = chunk.split('\n');

                    for (const line of lines) {
                        if (line.startsWith('data: ')) {
                            const data = line.slice(6);
                            if (data === '[DONE]') {
                                setIsStreaming(false);
                                retryCountRef.current = 0;
                                return;
                            }

                            try {
                                const parsed = JSON.parse(data);
                                if (parsed.error) {
                                    throw new Error(parsed.error);
                                }
                                const content = parsed.choices?.[0]?.delta?.content;
                                if (content) {
                                    accumulatedText += content;
                                    setStreamingText(accumulatedText);
                                }
                            } catch (e) {
                                console.warn('Error parsing chunk:', e);
                                if (e.message.includes('Firebase')) {
                                    throw new Error('Authentication error');
                                }
                            }
                        }
                    }
                }
            } finally {
                reader.releaseLock();
            }
        } catch (error) {
            console.error('Streaming error:', error);
            setStreamError(error.message);

            // Handle retry logic
            if (retryAttempt < MAX_RETRIES) {
                const delay = RETRY_DELAYS[retryAttempt];
                console.log(`Retrying stream in ${delay}ms...`);
                
                retryTimeoutRef.current = setTimeout(() => {
                    startStreaming(transcription, retryAttempt + 1);
                }, delay);
                return;
            }

            setStreamingText(prev => 
                prev + '\n\nError: Failed to load complete response. Please try again.');
            setIsStreaming(false);
        }
    }, [user.accessToken, sessionDate, tempDirRef, selectedModule, cleanup]);

    // Enhanced cleanup that also handles state
    const fullCleanup = useCallback(() => {
        cleanup();
        setIsStreaming(false);
        setStreamingText('');
        setStreamError(null);
    }, [cleanup]);

    return {
        isStreaming,
        streamingText,
        startStreaming,
        cleanup: fullCleanup,
        streamError
    };
};