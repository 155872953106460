import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Grid,
  Button,
  Avatar,
  Chip,
  Tooltip,
  Paper,
  IconButton,
} from '@mui/material';
import {
  Timeline,
  Assessment,
  TrendingUp,
  CalendarToday,
  ArrowForward,
  MenuBook,
  AccessTime,
  People,
  WorkspacePremium,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useErrorBoundary } from 'react-error-boundary';
import { doc, getDoc, db } from '../../../Firebase';
import { getDownloadURL, ref, storage } from '../../../Firebase';
import { useUser } from '../../../contexts/UserContext';
import { URL } from "../../../Helpers";
import LoadingSpinner from '../shared/LoadingSpinner';
import axios from 'axios';
import CollectionsIcon from '@mui/icons-material/Collections';
const COLORS = {
  primary: '#5C83BF',
  secondary: '#475569',
  accent: '#3b82f6',
  success: '#059669',
  warning: '#d97706',
  error: '#dc2626',
  background: '#f8fafc',
  background_main: '#FFF7F1',
  surface: '#ffffff',
  border: '#e2e8f0',
  text: {
    primary: '#1e293b',
    secondary: '#64748b'
  }
};

const TOTAL_WEEKLY_TARGET = 3;

const HomeCarousel = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { showBoundary } = useErrorBoundary();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [loading, setLoading] = useState(true);
  const [sessionData, setSessionData] = useState({
    dailySessions: {},
    weeklyStreak: 0,
    totalSessions: 0,
    averageSessionsPerWeek: 0,
    bestStreak: 0,
    maxSessionsInDay: 0,
    activeDaysThisWeek: 0,
    thisWeekSessions: []
  });
  const [podcastLinks, setPodcastLinks] = useState([]);
  const [podcastTitles, setPodcastTitles] = useState([]);
  const [sessionsDone, setSessionsDone] = useState(0);
  const [blogID, setBlogID] = useState(null);

  const calculateStreak = useCallback((sessionCounts) => {
    let currentStreak = 0;
    let bestStreak = 0;
    let totalSessions = 0;
    let weeksWithSessions = 0;

    const weeks = Object.entries(sessionCounts)
      .filter(([key]) => key !== 'total_session' && key !== 'this_week')
      .sort(([weekA], [weekB]) => {
        const dateA = weekA.split('_')[0];
        const dateB = weekB.split('_')[0];
        return new Date(dateB) - new Date(dateA);
      });

    for (const [_, weekData] of weeks) {
      if (weekData.count > 0) {
        currentStreak++;
        totalSessions += weekData.count;
        weeksWithSessions++;
      } else break;
    }

    if (sessionCounts.this_week?.count) {
      totalSessions += sessionCounts.this_week.count;
      weeksWithSessions++;
    }

    let tempStreak = 0;
    for (const [_, weekData] of weeks) {
      if (weekData.count > 0) {
        tempStreak++;
        bestStreak = Math.max(bestStreak, tempStreak);
      } else tempStreak = 0;
    }

    return {
      currentStreak,
      bestStreak,
      totalSessions,
      averageSessionsPerWeek: weeksWithSessions > 0 
        ? (totalSessions / weeksWithSessions).toFixed(1) 
        : 0
    };
  }, []);

  const processSessionsData = useCallback((sessions) => {
    const dailySessions = {};
    let maxSessionsInDay = 0;

    sessions.forEach(session => {
      const date = session.split('T')[0];
      if (!dailySessions[date]) {
        dailySessions[date] = { count: 1, moodRep: null };
      } else {
        dailySessions[date].count++;
      }
      maxSessionsInDay = Math.max(maxSessionsInDay, dailySessions[date].count);
    });

    return { dailySessions, maxSessionsInDay };
  }, []);

  const fetchCalendarData = async (idToken) => {
    try {
      const response = await fetch(`${URL}/user_calendar`, {
        headers: { 
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
      });
      
      if (!response.ok) {
        console.warn(`Calendar API returned status: ${response.status}`);
        return [];
      }
      
      return await response.json();
    } catch (error) {
      console.warn('Could not fetch calendar data:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchWeeklyChallenge = async () => {
      try {
        const idToken = await user.getIdToken();
        const currentDate = new Date();
        const currentWeekStart = new Date(
          currentDate.setUTCDate(currentDate.getUTCDate() - currentDate.getUTCDay())
        ).toISOString().split('T')[0];

        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (!userDocSnap.exists()) {
          fetchDataFromBackend(idToken, currentWeekStart);
          return;
        }

        const sessionCount = userDocSnap.data()?.session_count || {};
        const thisWeek = sessionCount.this_week || {};

        if (!thisWeek.week_start_challenge || thisWeek.week_start_challenge !== currentWeekStart) {
          fetchDataFromBackend(idToken, currentWeekStart);
        } else {
          setPodcastLinks(thisWeek.podcast_links);
          setPodcastTitles(thisWeek.podcast_titles);
          setSessionsDone(thisWeek.count);
          setBlogID(thisWeek.blog_id);
        }
      } catch (error) {
        console.error('Error updating weekly:', error);
        showBoundary(error);
      }
    };

    const fetchDataFromBackend = async (idToken, currentWeekStart) => {
      try {
        const response = await axios.get(`${URL}/update_weekly_challenge`, {
          headers: { 'Authorization': `Bearer ${idToken}` },
        });
        const data = response.data;
        setPodcastLinks(data.podcast_links);
        setPodcastTitles(data.podcast_titles);
        setSessionsDone(data.sessions_done);
        setBlogID(data.blog_id);
      } catch (error) {
        console.error('Error fetching data from backend:', error);
        showBoundary(error);
      }
    };

    fetchWeeklyChallenge();
  }, [user, showBoundary]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;
      
      try {
        setLoading(true);
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        
        const idToken = await user.getIdToken();
        const calendarData = await fetchCalendarData(idToken);
        
        if (!userDocSnap.exists()) {
          const defaultSessionData = {
            dailySessions: {},
            weeklyStreak: 0,
            totalSessions: 0,
            averageSessionsPerWeek: 0,
            bestStreak: 0,
            maxSessionsInDay: 0,
            activeDaysThisWeek: 0,
            thisWeekSessions: []
          };
          
          setSessionData(defaultSessionData);
          setLoading(false);
          return;
        }

        const userData = userDocSnap.data();
        const sessionCount = userData.session_count || {};
        const thisWeek = sessionCount.this_week || {};
        const streakData = calculateStreak(sessionCount);
        
        const { dailySessions, maxSessionsInDay } = processSessionsData(
          thisWeek.sessions || []
        );

        if (calendarData.length > 0) {
          const currentDate = new Date();
          const weekStart = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
          weekStart.setHours(0,0,0,0);

          const thisWeekDocs = calendarData.filter(doc => {
            const docDate = new Date(doc.date);
            return docDate >= weekStart;
          });

          for (const doc of thisWeekDocs) {
            const date = new Date(doc.date).toISOString().split('T')[0];
            if (dailySessions[date] && doc.moodrep) {
              try {
                const moodrepRef = ref(storage, doc.moodrep);
                const moodrepUrl = await getDownloadURL(moodrepRef);
                dailySessions[date].moodRep = moodrepUrl;
              } catch (error) {
                console.warn(`Failed to get download URL for ${doc.moodrep}:`, error);
                dailySessions[date].moodRep = null;
              }
            }
          }
        }

        setSessionData({
          dailySessions,
          weeklyStreak: streakData.currentStreak,
          totalSessions: sessionCount.total || 0,
          averageSessionsPerWeek: streakData.averageSessionsPerWeek,
          bestStreak: streakData.bestStreak,
          maxSessionsInDay,
          activeDaysThisWeek: Object.keys(dailySessions).length,
          thisWeekSessions: thisWeek.sessions || []
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        const defaultSessionData = {
          dailySessions: {},
          weeklyStreak: 0,
          totalSessions: 0,
          averageSessionsPerWeek: 0,
          bestStreak: 0,
          maxSessionsInDay: 0,
          activeDaysThisWeek: 0,
          thisWeekSessions: []
        };
        
        setSessionData(defaultSessionData);
        showBoundary(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [user, showBoundary, calculateStreak, processSessionsData]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <LoadingSpinner text="Loading leadership dashboard..." />
      </Box>
    );
  }

  return (
    // reduce the hight
    <Box sx={{ 
      p: 3, 
      bgcolor: COLORS.background_main,
      width: '100%', 
      display: 'flex', 
      justifyContent: 'center',
      // Remove minHeight to prevent extra scrolling
      height: 'fit-content'  
    }}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <Card 
            elevation={0} 
            sx={{ 
              bgcolor: COLORS.surface, 
              borderRadius: 2,
              border: `1px solid ${COLORS.border}`
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
                <Box display="flex" alignItems="center" gap={2}>
                  <Avatar
                    sx={{ 
                      bgcolor: `${COLORS.primary}15`,
                      color: COLORS.primary,
                    }}
                  >
                    <Assessment />
                  </Avatar>
                  <Box>
                    <Typography 
                      variant="h6" 
                      color={COLORS.text.primary}
                      fontWeight={600}
                    >
                      Leadership Progress
                    </Typography>
                    <Typography variant="body2" color={COLORS.text.secondary}>
                      Weekly Performance Metrics
                    </Typography>
                  </Box>
                </Box>
                {/* button without text */}
                <Button
                variant="outlined"
                onClick={() => navigate('/my-collection')}
                startIcon={<CollectionsIcon />}
                sx={{
                  borderColor: COLORS.border,
                  color: COLORS.primary,
                  '&:hover': {
                    borderColor: COLORS.primary,
                    bgcolor: `${COLORS.primary}08`,
                  },
                  transition: 'all 0.3s ease'
                }}
              >
              Collection
              </Button>
              </Box>

              <Grid container spacing={3} mb={4}>
                <Grid item xs={12} sm={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2.5,
                      bgcolor: COLORS.background,
                      borderRadius: 2,
                      border: `1px solid ${COLORS.border}`
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                      <Typography variant="body2" color={COLORS.text.secondary}>
                        Weekly Sessions
                      </Typography>
                      <AccessTime sx={{ color: COLORS.accent, fontSize: 20 }} />
                    </Box>
                    <Typography variant="h4" color={COLORS.text.primary} fontWeight={600} mb={2}>
                      {sessionData.thisWeekSessions.length}
                      <Typography
                        component="span"
                        variant="body1"
                        color={COLORS.text.secondary}
                        ml={1}
                      >
                        / {TOTAL_WEEKLY_TARGET}
                      </Typography>
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={(sessionData.thisWeekSessions.length / TOTAL_WEEKLY_TARGET) * 100}
                      sx={{
                        height: 6,
                        borderRadius: 3,
                        bgcolor: `${COLORS.accent}15`,
                        '& .MuiLinearProgress-bar': {
                          bgcolor: COLORS.accent,
                          borderRadius: 3,
                        }
                      }}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2.5,
                      bgcolor: COLORS.background,
                      borderRadius: 2,
                      border: `1px solid ${COLORS.border}`
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                      <Typography variant="body2" color={COLORS.text.secondary}>
                        Active Days
                      </Typography>
                      <CalendarToday sx={{ color: COLORS.success, fontSize: 20 }} />
                    </Box>
                    <Typography variant="h4" color={COLORS.text.primary} fontWeight={600} mb={2}>
                      {sessionData.activeDaysThisWeek}
                      <Typography
                        component="span"
                        variant="body1"
                        color={COLORS.text.secondary}
                        ml={1}
                      >
                        days
                      </Typography>
                    </Typography>
                    <Box display="flex" alignItems="center" gap={1}>
                      <TrendingUp sx={{ color: COLORS.success, fontSize: 16 }} />
                      <Typography variant="body2" color={COLORS.success}>
                        {Math.round((sessionData.activeDaysThisWeek / 7) * 100)}% weekly activity
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2.5,
                      bgcolor: COLORS.background,
                      borderRadius: 2,
                      border: `1px solid ${COLORS.border}`
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                      <Typography variant="body2" color={COLORS.text.secondary}>
                        Total Sessions
                      </Typography>
                      <WorkspacePremium sx={{ color: COLORS.warning, fontSize: 20 }} />
                    </Box>
                    <Typography variant="h4" color={COLORS.text.primary} fontWeight={600} mb={2}>
                      {sessionData.totalSessions}
                    </Typography>
                    <Typography variant="body2" color={COLORS.text.secondary}>
                      {sessionData.averageSessionsPerWeek} avg/week
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              <Typography variant="h6" color={COLORS.text.primary} fontWeight={600} mb={3}>
                Leadership Insights
              </Typography>
              
              <Grid container spacing={2}>
                {Object.entries(sessionData.dailySessions).map(([date, data]) => (
                  <Grid item xs={12} sm={6} md={4} key={date}>
                    <Paper
                      elevation={0}
                      sx={{
                        position: 'relative',
                        borderRadius: 2,
                        overflow: 'hidden',
                        border: `1px solid ${COLORS.border}`,
                        height: 200,
                        transition: 'all 0.3s ease'
                      }}
                    >
                      {data.moodRep ? (
                        <Box
                          sx={{
                            height: '100%',
                            width: '100%',
                            position: 'relative'
                          }}
                        >
                          <img
                            src={data.moodRep}
                            alt={`Session on ${date}`}
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 30%, rgba(0,0,0,0) 100%)',
                              p: 2,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <Typography variant="body2" color="white" fontWeight={500}>
                              {new Date(date).toLocaleDateString('en-US', {
                                weekday: 'long',
                                month: 'long',
                                day: 'numeric'
                              })}
                            </Typography>
                            <Typography variant="caption" color="rgba(255,255,255,0.8)">
                              {data.count} {data.count === 1 ? 'session' : 'sessions'}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            height: '100%',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bgcolor: `${COLORS.accent}08`
                          }}
                        >
                          <Typography variant="body1" color={COLORS.text.primary} align="center" mb={1}>
                            {new Date(date).toLocaleDateString('en-US', {
                              weekday: 'long',
                              month: 'long',
                              day: 'numeric'
                            })}
                          </Typography>
                          <Chip
                            label={`${data.count} ${data.count === 1 ? 'session' : 'sessions'}`}
                            size="small"
                            sx={{
                              bgcolor: `${COLORS.accent}15`,
                              color: COLORS.accent,
                              fontWeight: 500
                            }}
                          />
                        </Box>
                      )}
                    </Paper>
                  </Grid>
                ))}
                
                {/* Empty slots */}
                {Array.from({ length: TOTAL_WEEKLY_TARGET - Object.keys(sessionData.dailySessions).length }).map((_, i) => (
                  <Grid item xs={12} sm={6} md={4} key={`empty-${i}`}>
                    <Paper
                      elevation={0}
                      sx={{
                        height: 200,
                        borderRadius: 2,
                        border: `1px dashed ${COLORS.border}`,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: `${COLORS.accent}04`,
                        transition: 'all 0.3s ease'
                      }}
                    >
                      <CalendarToday 
                        sx={{ 
                          color: COLORS.text.secondary,
                          fontSize: 32,
                          mb: 1,
                          opacity: 0.5
                        }} 
                      />
                      <Typography variant="body2" color={COLORS.text.secondary}>
                        Scheduled Session {Object.keys(sessionData.dailySessions).length + i + 1}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>

              <Box display="flex" justifyContent="center" mt={4}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => navigate('/session')}
                  startIcon={<WorkspacePremium />}
                  sx={{
                    bgcolor: COLORS.primary,
                    color: 'white',
                    px: 4,
                    py: 1.5,
                    borderRadius: 90,
                    textTransform: 'none',
                    fontWeight: 500,
                    '&:hover': {
                      bgcolor: COLORS.secondary,
                    },
                    transition: 'all 0.3s ease'
                  }}
                >
                  Start Leadership Session
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Card 
            elevation={0} 
            sx={{ 
              bgcolor: COLORS.surface,
              borderRadius: 2,
              border: `1px solid ${COLORS.border}`
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center" gap={2} mb={4}>
                <Avatar
                  sx={{ 
                    bgcolor: `${COLORS.warning}15`,
                    color: COLORS.warning
                  }}
                >
                  <MenuBook />
                </Avatar>
                <Box>
                  <Typography 
                    variant="h6" 
                    color={COLORS.text.primary}
                    fontWeight={600}
                  >
                    Learning Resources
                  </Typography>
                  <Typography variant="body2" color={COLORS.text.secondary}>
                    Weekly Materials
                  </Typography>
                </Box>
              </Box>

              {podcastLinks.length > 0 ? (
                <Box>
                  {podcastLinks.slice(0, 2).map((link, index) => (
                    <Paper
                      key={index}
                      elevation={0}
                      onClick={() => window.open(link, '_blank')}
                      sx={{
                        p: 2,
                        mb: 2,
                        cursor: 'pointer',
                        borderRadius: 2,
                        border: `1px solid ${COLORS.border}`,
                        bgcolor: COLORS.background,
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          bgcolor: `${COLORS.accent}08`,
                          transform: 'translateY(-2px)'
                        }
                      }}
                    >
                      <Box display="flex" alignItems="center" gap={2}>
                        <Avatar
                          variant="rounded"
                          sx={{ 
                            bgcolor: `${COLORS.accent}15`,
                            color: COLORS.accent
                          }}
                        >
                          <People />
                        </Avatar>
                        <Box>
                          <Typography variant="body1" color={COLORS.text.primary} fontWeight={500}>
                            {podcastTitles[index]}
                          </Typography>
                          <Typography variant="caption" color={COLORS.text.secondary}>
                            Leadership Podcast
                          </Typography>
                        </Box>
                        <IconButton 
                          size="small" 
                          sx={{ 
                            ml: 'auto',
                            color: COLORS.accent
                          }}
                        >
                          <ArrowForward />
                        </IconButton>
                      </Box>
                    </Paper>
                  ))}

                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => window.location.href = 'https://rss.com/podcasts/pokapod/'}
                    sx={{
                      mt: 2,
                      borderColor: COLORS.border,
                      color: COLORS.text.primary,
                      textTransform: 'none',
                      '&:hover': {
                        borderColor: COLORS.accent,
                        bgcolor: `${COLORS.accent}08`,
                      }
                    }}
                  >
                    View All Resources
                  </Button>
                </Box>
              ) : (
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    border: `1px solid ${COLORS.border}`,
                    bgcolor: COLORS.background,
                    textAlign: 'center'
                  }}
                >
                  <Typography variant="body1" color={COLORS.text.primary} mb={1}>
                    New content coming soon
                  </Typography>
                  <Typography variant="body2" color={COLORS.text.secondary}>
                    Check back for updated leadership resources
                  </Typography>
                </Paper>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeCarousel;