// HomePage.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import HomeCarousel from './HomeCarousel';
import OnboardingTour from './onboarding';
import './HomePage.scss';

const HomePage = () => {
  return (
    <Box 
      className="prevent_overflow"
      sx={{
        bgcolor: '#FFF7F1',
        height: 'auto',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        pt: 3,
        pb: 3
      }}
    >
      <OnboardingTour />

      <Box
        sx={{
          width: '100%',
          maxWidth: '1440px',
          mx: 'auto',
          px: { xs: 2, sm: 3, md: 4 }
        }}
      >
        <Grid 
          className='home-slide' 
          container 
          alignItems='flex-start'
          justifyContent="center"
          sx={{ 
            p: 0, 
            m: 0,
            width: '100%'
          }}
        >
          <HomeCarousel />
        </Grid>
      </Box>
    </Box>
  );
};

export default HomePage;