import React, { useState, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import Recorder from 'recorder-js';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  LinearProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  useMediaQuery,
  Paper,
  Alert,
  IconButton
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import {
  VideocamOutlined,
  VideocamOffOutlined,
  MicOutlined,
  ArrowForwardIos,
  VolumeUp
} from '@mui/icons-material';

const MediaTest = ({ onSuccess, onHide, show, value, onContinue, videoAccess, setVideoAccess }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [micStatus, setMicStatus] = useState(false);
  const [amplitude, setAmplitude] = useState(0);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const webcamRef = useRef(null);
  const audioRecorderRef = useRef(null);
  const audioStreamRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    initializeDevices();
    return cleanup;
  }, []);

  const initializeDevices = async () => {
    try {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const recorder = new Recorder(audioContext);
      audioRecorderRef.current = recorder;
      
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      recorder.init(stream);
      recorder.start();
      audioStreamRef.current = stream;

      const analyser = audioContext.createAnalyser();
      audioContext.createMediaStreamSource(stream).connect(analyser);
      const data = new Uint8Array(analyser.frequencyBinCount);
      
      const update = () => {
        analyser.getByteFrequencyData(data);
        const value = data.reduce((acc, val) => acc + val, 0);
        const avgAmplitude = value / data.length;
        setAmplitude(avgAmplitude * 5);
        setMicStatus(avgAmplitude > 0.01);
        requestAnimationFrame(update);
      };
      update();

      setVideoAccess(true);
    } catch (err) {
      setError('Please check your microphone access - this is required for the session.');
    }
  };

  const cleanup = () => {
    if (audioRecorderRef.current) {
      audioRecorderRef.current.stop();
      audioRecorderRef.current = null;
    }
    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach(track => track.stop());
      audioStreamRef.current = null;
    }
  };

  const handleCameraToggle = () => {
    if (videoAccess) {
      setOpenDialog(true);
    } else {
      setVideoAccess(true);
    }
  };

  const handleDisableCamera = () => {
    setVideoAccess(false);
    setOpenDialog(false);
  };

  return (
    <Box sx={{
      minHeight: '100vh',
      bgcolor: '#FFF7F1',
      p: { xs: 2, sm: 3, md: 4 },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          maxWidth: 1000,
          overflow: 'hidden',
          borderRadius: '24px',
          border: '1px solid #E3DBD5',
          bgcolor: '#FFFFFF',
          position: 'relative'
        }}
      >
        {/* Gradient Accent */}
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '4px',
          background: 'linear-gradient(90deg, #5C83BF 0%, #4C6EA2 100%)'
        }} />

        {/* Header */}
        <Box sx={{
          bgcolor: '#5C83BF',
          p: { xs: 3, sm: 4 },
          background: 'linear-gradient(145deg, #5C83BF 0%, #4C6EA2 100%)',
        }}>
          <Typography 
            variant={isMobile ? "h5" : "h4"} 
            sx={{ 
              color: 'white',
              fontWeight: 500,
              letterSpacing: '-0.02em',
              mb: 1
            }}
          >
            Welcome to Your Session
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              color: 'rgba(255,255,255,0.9)',
              maxWidth: 600,
              fontWeight: 300
            }}
          >
            Let's quickly check your devices before we begin
          </Typography>
        </Box>

        {/* Main Content */}
        <Box sx={{ p: { xs: 3, sm: 4 } }}>
          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 4,
                borderRadius: '16px',
                border: '1px solid rgba(211, 47, 47, 0.1)'
              }}
            >
              {error}
            </Alert>
          )}

          <Grid container spacing={4}>
            {/* Camera Preview */}
            <Grid xs={12} md={7}>
              <Box sx={{
                position: 'relative',
                height: 0,
                paddingTop: '62.5%',
                bgcolor: '#F8F9FA',
                borderRadius: '20px',
                overflow: 'hidden',
                border: '1px solid rgba(76, 110, 162, 0.1)',
                boxShadow: '0 4px 24px rgba(76, 110, 162, 0.05)'
              }}>
                {videoAccess ? (
                  <Webcam
                    ref={webcamRef}
                    audio={false}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    onUserMediaError={() => {
                      setVideoAccess(false);
                      setError('Camera access is not available. You can proceed without video.');
                    }}
                  />
                ) : (
                  <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 2,
                    color: '#4C6EA2'
                  }}>
                    <VideocamOffOutlined sx={{ fontSize: 48, opacity: 0.5 }} />
                    <Typography variant="body2" sx={{ opacity: 0.7 }}>
                      Camera is disabled
                    </Typography>
                  </Box>
                )}

                {/* Camera Toggle Button */}
                <Button
                  variant="contained"
                  onClick={handleCameraToggle}
                  sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    borderRadius: '12px',
                    bgcolor: 'rgba(76, 110, 162, 0.9)',
                    backdropFilter: 'blur(8px)',
                    '&:hover': {
                      bgcolor: '#4C6EA2'
                    },
                    textTransform: 'none',
                    px: 2,
                    py: 1
                  }}
                  startIcon={videoAccess ? <VideocamOutlined /> : <VideocamOffOutlined />}
                >
                  {videoAccess ? 'Disable Camera' : 'Enable Camera'}
                </Button>
              </Box>
            </Grid>

            {/* Controls Section */}
            <Grid xs={12} md={5}>
              <Box sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 4
              }}>
                {/* Microphone Test */}
                <Box sx={{
                  bgcolor: 'rgba(76, 110, 162, 0.03)',
                  p: 3,
                  borderRadius: '20px',
                  border: '1px solid rgba(76, 110, 162, 0.1)'
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1.5,
                    mb: 2.5
                  }}>
                    <VolumeUp 
                      sx={{ 
                        color: micStatus ? '#4C6EA2' : 'rgba(76, 110, 162, 0.5)',
                        fontSize: 28
                      }} 
                    />
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: 500,
                        color: '#4C6EA2'
                      }}
                    >
                      Test Your Microphone
                    </Typography>
                  </Box>

                  <Box sx={{ mb: 2 }}>
                    <LinearProgress
                      variant="determinate"
                      value={amplitude}
                      sx={{
                        height: 10,
                        borderRadius: '8px',
                        bgcolor: 'rgba(76, 110, 162, 0.1)',
                        '& .MuiLinearProgress-bar': {
                          bgcolor: micStatus ? '#4C6EA2' : 'rgba(76, 110, 162, 0.3)',
                          transition: 'all 0.2s ease'
                        }
                      }}
                    />
                  </Box>

                  <Typography 
                    variant="body2" 
                    sx={{ 
                      color: 'rgba(76, 110, 162, 0.7)',
                      textAlign: 'center',
                      fontStyle: 'italic'
                    }}
                  >
                    Say "myPoka" to test your microphone
                  </Typography>
                </Box>

                {/* Start Session Button */}
                <Button
                  variant="contained"
                  onClick={onContinue}
                  sx={{
                    bgcolor: '#4C6EA2',
                    '&:hover': {
                      bgcolor: '#3d5a84',
                      transform: 'translateY(-1px)',
                      boxShadow: '0 6px 20px rgba(76, 110, 162, 0.25)'
                    },
                    borderRadius: '14px',
                    py: 2,
                    px: 4,
                    textTransform: 'none',
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    boxShadow: '0 4px 14px rgba(76, 110, 162, 0.2)',
                    transition: 'all 0.2s ease'
                  }}
                  endIcon={<ArrowForwardIos sx={{ fontSize: 18 }} />}
                >
                  Start Session
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {/* Camera Disable Warning Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            maxWidth: '450px',
            mx: 2
          }
        }}
      >
        <Box sx={{
          background: 'linear-gradient(145deg, #5C83BF 0%, #4C6EA2 100%)',
          p: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1.5
        }}>
          <VideocamOffOutlined sx={{ color: 'white' }} />
          <Typography variant="h6" sx={{ color: 'white', fontWeight: 500 }}>
            Disable Camera?
          </Typography>
        </Box>

        <DialogContent sx={{ mt: 2 }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}>
            <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
              Your camera helps us analyze:
            </Typography>
            
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column',
              gap: 1.5,
              pl: 2 
            }}>
              {[
                'Facial expressions',
                'Non-verbal communication signals',
                'Overall engagement patterns',
                'Visual interaction styles'
              ].map((item, index) => (
                <Box key={index} sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 2 
                }}>
                  <Box sx={{
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    bgcolor: '#4C6EA2',
                    opacity: 0.7
                  }} />
                  <Typography variant="body2" color="text.secondary">
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Typography 
              variant="body2" 
              sx={{ 
                mt: 1,
                color: 'text.secondary',
                bgcolor: 'rgba(76, 110, 162, 0.05)',
                p: 2,
                borderRadius: '12px',
                border: '1px solid rgba(76, 110, 162, 0.1)'
              }}
            >
              Without video access, our communication style analysis will rely solely on voice patterns, potentially reducing the accuracy of your analysis after the session.
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 2.5, gap: 1 }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            sx={{ 
              color: '#4C6EA2',
              '&:hover': {
                bgcolor: 'rgba(76, 110, 162, 0.05)'
              }
            }}
          >
            Keep Camera On
          </Button>
          <Button
            variant="contained"
            onClick={handleDisableCamera}
            sx={{
              bgcolor: '#4C6EA2',
              '&:hover': {
                bgcolor: '#3d5a84'
              },
              borderRadius: '10px',
              textTransform: 'none'
            }}
          >
            Disable Camera
            </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MediaTest;