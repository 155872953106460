import React, { useState, useCallback, useMemo, memo, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { db, doc, getDoc } from "../../../Firebase";
import { tierLimits } from '../../../user/pages/session/mySessionUtils';
import { useMySessionLogic } from './useMySessionLogic';
import { formatTime } from '../../../user/pages/session/mySessionUtils';
import { useUser } from '../../../contexts/UserContext';
import { useErrorBoundary } from "react-error-boundary";
import audiofile from '../../start.wav';
import audioending from '../../end.wav';
import "./MySession.scss";

// Lazy load components that aren't needed immediately
const RecordingUI = lazy(() => import('./RecordingUI'));
const MediaTest = lazy(() => import("../../../user/pages/session/MediaTest"));
const LoadingSpinner = lazy(() => import("../shared/LoadingSpinner"));
const SessionResponseSpinner = lazy(() => import("../shared/SessionResponseSpinner"));
const TopicSelection = lazy(() => import('./TopicSelection'));

// Memoized Modal Components
const LimitErrorModal = memo(({ limitError, onClose }) => (
  <Dialog open={!!limitError} onClose={onClose}>
    <DialogTitle>
      <Typography variant="h6" color="primary.main">
        {limitError?.reason}
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography>
        {limitError?.message}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
));

const UpgradeModal = memo(({ open, onClose, onUpgrade }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      <Typography variant="h6" color="primary.main">
        Upgrade to Premium
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography gutterBottom>
        Unlock unlimited daily sessions and extended session duration with our Premium plan!
      </Typography>
      <Box mt={2}>
        <Typography variant="subtitle1" color="primary.main" gutterBottom>
          Premium Benefits:
        </Typography>
        <ul>
          <li>12 sessions per week</li>
          <li>No daily session limits</li>
          <li>10-minute session duration</li>
        </ul>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="inherit">
        Maybe Later
      </Button>
      <Button
        onClick={onUpgrade}
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: "#5C83BF",
          '&:hover': {
            backgroundColor: "#4C6EA2"
          }
        }}
      >
        Upgrade Now
      </Button>
    </DialogActions>
  </Dialog>
));

// Error Message Component
const ErrorMessage = memo(({ message }) => (
  <Typography
    variant="h32Bold"
    color="primary.darkerBlue"
    className="text-center comfortaa_font mb-4"
  >
    {message}
  </Typography>
));

const MySession = () => {
  const navigate = useNavigate();
  const { user, tier, role } = useUser();
  const { showBoundary } = useErrorBoundary();
  
  // State management with reducers for related states
  const [sessionState, setSessionState] = useState({
    selectedTopic: null,
    showTopicSelection: true,
    limitError: false,
    showUpgradeModal: false
  });

  // Memoize session logic to prevent unnecessary re-renders
  const sessionLogic = useMySessionLogic();
  const {
    selectedModule,
    setSelectedModule,
    loading,
    responseLoading,
    recording,
    startRecording: originalStartRecording,
    // Add streamingLogic to destructuring
    streamingLogic,
    ...otherSessionLogic
  } = sessionLogic;

  // Memoize session limit checking logic
  const checkSessionLimits = useCallback(async () => {
    const todayStr = new Date().toISOString().split('T')[0];
    const weekStart = new Date();
    weekStart.setDate(weekStart.getDate() - weekStart.getDay() + (weekStart.getDay() === 0 ? -6 : 1));
    const weekStartStr = weekStart.toISOString().split('T')[0];

    try {
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (!userDoc.exists()) {
        showBoundary(new Error("User not found"));
        return { allowed: false };
      }

      const data = userDoc.data();
      const { session_count: sessionCount = {} } = data;
      const { this_week: thisWeek = {}, this_day: thisDay = {} } = sessionCount;
      
      const sessionsThisWeek = thisWeek.count || 0;
      const sessionsToday = thisDay.count || 0;

      // Free tier checks
      if (tier === 'free') {
        if (tierLimits.free.dailyLimit && thisDay.date === todayStr && thisDay.totalDuration > 0) {
          return {
            allowed: false,
            reason: "Daily Limit Reached",
            message: "You've reached your daily session limit. Upgrade to Premium for unlimited sessions!",
            showUpgrade: true
          };
        }

        if (sessionsThisWeek > 0) {
          return {
            allowed: false,
            reason: "Weekly Limit Reached",
            message: "You've reached your weekly session limit. Upgrade to Premium for more sessions!",
            showUpgrade: true
          };
        }
      }

      // Premium tier checks
      if (tier === 'premium') {
        if (!thisWeek.week_start !== weekStartStr && sessionsThisWeek >= tierLimits.premium.weeklyLimit) {
          return {
            allowed: false,
            reason: "Weekly Limit Reached",
            message: "You've reached your weekly session limit.",
            showUpgrade: false
          };
        }

        if (thisDay.date === todayStr) {
          const totalDurationToday = thisDay.totalDuration || 0;
          if (totalDurationToday >= tierLimits.premium.dailyDurationLimit) {
            return {
              allowed: false,
              reason: "Daily Duration Limit Reached",
              message: "You've reached your daily session duration limit.",
              showUpgrade: false
            };
          }
        }
      }

      return { allowed: true };
    } catch (error) {
      showBoundary(error);
      return { allowed: false };
    }
  }, [user.uid, tier, showBoundary]);

  // Memoized handlers
  const startRecording = useCallback(async () => {
    const limitCheck = await checkSessionLimits();
    
    if (!limitCheck.allowed) {
      setSessionState(prev => ({
        ...prev,
        limitError: {
          reason: limitCheck.reason,
          message: limitCheck.message
        },
        showUpgradeModal: limitCheck.showUpgrade
      }));
      return;
    }
    originalStartRecording(sessionState.selectedTopic);
  }, [checkSessionLimits, originalStartRecording, sessionState.selectedTopic]);

  const handleUpgrade = useCallback(() => {
    navigate('/plans');
  }, [navigate]);

  const handleTopicSelect = useCallback((topicId) => {
    setSelectedModule(topicId);
    setSessionState(prev => ({
      ...prev,
      selectedTopic: topicId,
      showTopicSelection: false
    }));
  }, [setSelectedModule]);

  // Memoized modal handlers
  const handleCloseLimitError = useCallback(() => {
    setSessionState(prev => ({ ...prev, limitError: false }));
  }, []);

  const handleCloseUpgradeModal = useCallback(() => {
    setSessionState(prev => ({ ...prev, showUpgradeModal: false }));
  }, []);

  // Render loading states
  if (loading) {
    return (
      <Suspense fallback={null}>
        <LoadingSpinner className="bigger_height" />
      </Suspense>
    );
  }

  if (responseLoading) {
    return (
      <Suspense fallback={null}>
        <SessionResponseSpinner />
      </Suspense>
    );
  }

  // Render topic selection
  if (sessionState.showTopicSelection) {
    return (
      <div className="prevent_overflow">
        <Suspense fallback={null}>
          <TopicSelection onTopicSelect={handleTopicSelect} />
        </Suspense>
      </div>
    );
  }

  // Main render
  return (
    <div className="prevent_overflow">
      <LimitErrorModal 
        limitError={sessionState.limitError} 
        onClose={handleCloseLimitError}
      />
      <UpgradeModal 
        open={sessionState.showUpgradeModal}
        onClose={handleCloseUpgradeModal}
        onUpgrade={handleUpgrade}
      />

      {!recording && !loading && (
        <div>
          {sessionLogic.resultError && (
            <ErrorMessage message="Error: Analysis unclear. Please try again and wait for at least 15 seconds before stopping" />
          )}
          <Suspense fallback={null}>
            <MediaTest
              onContinue={startRecording}
              videoAccess={sessionLogic.videoAccess}
              setVideoAccess={sessionLogic.setVideoAccess}
            />
          </Suspense>
        </div>
      )}

      {recording && (
        <>
          <Suspense fallback={null}>
            <RecordingUI
              {...otherSessionLogic}
              countdown={sessionLogic.countdown}
              currentQuestion={sessionLogic.currentQuestion}
              buttonDisabled={sessionLogic.buttonDisabled}
              showQuestions={sessionLogic.showQuestions}
              toggleQuestions={sessionLogic.toggleQuestions}
              stopRecording={sessionLogic.stopRecording}
              responseLoading={responseLoading}
              formatTime={formatTime}
              streamingLogic={streamingLogic}  // Pass streamingLogic here

            />
          </Suspense>
          <div className="d-none justify-content-center align-items-center">
            <canvas ref={sessionLogic.audioCanvasRef} width={window.innerWidth}></canvas>
            <audio
              ref={sessionLogic.audioRef}
              src={audiofile}
              preload="auto"
              onLoadedMetadata={sessionLogic.loadVisualizer}
            ></audio>
            <audio
              ref={sessionLogic.audioEndingRef}
              src={audioending}
              preload="auto"
            ></audio>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(MySession);