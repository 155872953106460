import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useSession } from '../../../contexts/SessionContext';
import { useUser } from '../../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useErrorBoundary } from "react-error-boundary";
import { createSessionDir, tierLimits } from './mySessionUtils';
import Recorder from 'recorder-js';
import { v4 as uuid4 } from 'uuid';
import { URL, getDateString } from "../../../Helpers";
import { useStreamingLogic } from './useStreamingLogic';

export const useMySessionLogic = () => {
  const navigate = useNavigate();
  const { overviewData, setOverviewData, setSessionContextDate, setAnalysisData, setDbtData } = useSession();
  const { user, tier, role } = useUser();
  const { showBoundary } = useErrorBoundary();

  // State management
  const [recording, setRecording] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseLoading, setResponseLoading] = useState(false);
  const [question, setQuestion] = useState('');
  const [scenario, setScenario] = useState("");
  const [showQuestions, setShowQuestions] = useState(false);
  const [webcamVisible, setWebcamVisible] = useState(false);
  const [videoAccess, setVideoAccess] = useState(true);
  const [resultError, setresultError] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [WarningMessage, setWarningMessage] = useState(null);
  const [showAvatar, setShowAvatar] = useState(true);
  const [isFirstQuestion, setIsFirstQuestion] = useState(true);
  const [selectedModule, setSelectedModule] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const uploadQueueRef = useRef([]);
  const lastUploadTimeRef = useRef(0);


  // Refs
  const audioStreamRef = useRef(null);
  const tempDirRef = useRef(null);
  const imgTimeoutIDRef = useRef(null);
  const audioContextRef = useRef();
  const camRef = useRef(null);
  const audioRef = useRef(null);
  const audioCanvasRef = useRef(null);
  const micCanvasRef = useRef(null);
  const canvasParentRef = useRef(null);
  const countdownIntervalRef = useRef();
  const timeoutRef = useRef();
  const audioRecorderRef = useRef();
  const audioEndingRef = useRef();
  const sessionDate = useRef(getDateString());
  const sessionIDUnique = useRef(uuid4());
  const m4aRecorderRef = useRef(null);
  const m4aRecordingRef = useRef(null);

  // Get SESSION_TIMER based on tier
  const SESSION_TIMER = useMemo(() => tierLimits[tier]?.SESSION_TIMER || tierLimits.free.SESSION_TIMER, [tier]);
  const { isStreaming, streamingText, startStreaming, cleanup: cleanupStreaming } = useStreamingLogic(
    user, 
    sessionDate.current, 
    tempDirRef,
    selectedModule
  );
  
  // Warning handler
  const handleWarning = useCallback((message) => {
    setShowWarning(true);
    setWarningMessage(message);
    setTimeout(() => {
      setShowWarning(false);
      setWarningMessage(null);
    }, 1500);
  }, []);

  // Upload utility
  const uploadData = useCallback(async (data, url) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`,
          'X-Session-Date': sessionDate.current  // Add session date to headers
        },
        body: data
      });
  
      if (!response.ok) {
        handleWarning("Face not detected.");
        let msg = await response.json();
        throw new Error('Network response was not ok ' + msg.message);
      }
  
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  }, [user.accessToken, handleWarning]);

  // Timer initialization
  const loadVisualizer = useCallback(() => {
    if (!audioRef.current) return;
    setCountdown(SESSION_TIMER);
    startCountdown(SESSION_TIMER);

    // Only play audio for non-admin users
    if (role !== 'admin') {
      audioRef.current.play().catch(console.error);
    }
  }, [SESSION_TIMER, role]);

  // Countdown handler
  const startCountdown = useCallback((duration) => {
    let remainingTime = duration;
    setCountdown(remainingTime);

    setTimeout(() => {
      countdownIntervalRef.current = setInterval(() => {
        remainingTime -= 1;

        if (audioEndingRef.current && remainingTime === Math.floor(audioEndingRef.current.duration) + 2) {
          audioEndingRef.current.play().catch(console.error);
        }

        if (remainingTime <= 0) {
          setCountdown(null);
          setQuestion(null);
          clearInterval(countdownIntervalRef.current);
        } else {
          setCountdown(remainingTime);
        }
      }, 1000);
    }, 8000);
  }, []);



  const handleScreenshots = useCallback(() => {
    const sendImg = () => {
      if (!camRef.current) return;
  
      const img = camRef.current.getScreenshot()?.split(',')[1];
      if (!img) return;
  
      const currentTime = Date.now();
      const timestamp = currentTime / 1000; // For Mediapipe
      
      const imageData = JSON.stringify({
        image: img,
        timestamp: timestamp,
        iso_timestamp: new Date(currentTime).toISOString(), // For CSV recording
        session_id: sessionDate.current
      });
  
      uploadData(imageData, `${URL}/img/${sessionDate.current}/${tempDirRef.current}`);
      imgTimeoutIDRef.current = setTimeout(sendImg, 2000);
    };
  
    imgTimeoutIDRef.current = setTimeout(sendImg, 2000);
  }, [uploadData]);
  // Cleanup handler
     // Enhanced cleanup
     const cleanup = useCallback(() => {
      setRecording(false);
      setCountdown(null);
      setLoading(false);
      setQuestion('');
      setShowQuestions(false);
      setWebcamVisible(false);
      tempDirRef.current = null;

      // Audio cleanup
      if (audioRecorderRef.current) {
          audioRecorderRef.current.stop();
          audioRecorderRef.current = null;
      }

      if (m4aRecorderRef.current && m4aRecorderRef.current.state !== 'inactive') {
          m4aRecorderRef.current.stop();
      }

      if (audioStreamRef.current) {
          audioStreamRef.current.getTracks().forEach(track => {
              track.stop();
              track.enabled = false;
          });
          audioStreamRef.current = null;
      }

      if (audioContextRef.current?.state === 'running') {
          audioContextRef.current.close().catch(console.error);
          audioContextRef.current = null;
      }

      // Clear all timeouts and intervals
      [timeoutRef, imgTimeoutIDRef].forEach(ref => {
          if (ref.current) {
              clearTimeout(ref.current);
              ref.current = null;
          }
      });

      if (countdownIntervalRef.current) {
          clearInterval(countdownIntervalRef.current);
          countdownIntervalRef.current = null;
      }

      // Reset states
      setResponseLoading(false);
      setWarningMessage(null);
      setShowWarning(false);
      setIsUploading(false);
      uploadQueueRef.current = [];
  }, []);
  // Audio recording setup
  // Optimized audio recording setup
  const getSupportedMimeType = () => {
    const types = [
      'audio/mp4',
      'audio/webm',
      'audio/ogg',
      'audio/wav'
    ];
    
    for (const type of types) {
      if (MediaRecorder.isTypeSupported(type)) {
        console.log('Using MIME type:', type);
        return type;
      }
    }
    return null;
  };
  
  const startRecordingAudio = useCallback(async (stream) => {
    try {
        console.log('Starting audio recording setup');
        
        // Initialize audio context with original settings
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
            sampleRate: 22050,
            latencyHint: 'interactive',
            powerPreference: 'high-performance'
        });

        const sourceNode = audioContextRef.current.createMediaStreamSource(stream);
        const compressor = audioContextRef.current.createDynamicsCompressor();
        const highPassFilter = audioContextRef.current.createBiquadFilter();
        
        // Original compressor settings
        compressor.threshold.value = -24;
        compressor.knee.value = 30;
        compressor.ratio.value = 12;
        compressor.attack.value = 0.003;
        compressor.release.value = 0.25;

        highPassFilter.type = 'highpass';
        highPassFilter.frequency.value = 1000;

        sourceNode.connect(highPassFilter);
        highPassFilter.connect(compressor);

        console.log('Creating Recorder instance');
        const recorderInstance = new Recorder(audioContextRef.current, {
            numChannels: 1,
            sampleRate: 22050,
            encoderPath: '/encoderWorker.min.js',
            streamPages: false,
            bufferLength: 16384,
            audioBitsPerSecond: 16000
        });

        // Check supported MIME types
        const mimeType = MediaRecorder.isTypeSupported('audio/webm') 
            ? 'audio/webm' 
            : MediaRecorder.isTypeSupported('audio/ogg') 
                ? 'audio/ogg' 
                : 'audio/mp4';

        console.log('Selected MIME type:', mimeType);

        // Create new stream for MediaRecorder to avoid conflicts
        const audioTrack = stream.getAudioTracks()[0];
        const mediaRecorderStream = new MediaStream([audioTrack.clone()]);

        const m4aRecorder = new MediaRecorder(mediaRecorderStream, {
            mimeType,
            audioBitsPerSecond: 16000
        });

        const m4aChunks = [];
        m4aRecorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
                m4aChunks.push(e.data);
            }
        };

        m4aRecorder.onstop = () => {
            console.log('Creating audio blob');
            m4aRecordingRef.current = new Blob(m4aChunks, { 
                type: mimeType
            });
            m4aChunks.length = 0;
        };

        // Initialize recorders in sequence
        console.log('Initializing Recorder.js');
        await recorderInstance.init(stream);
        console.log('Starting Recorder.js');
        await recorderInstance.start();
        
        console.log('Starting MediaRecorder');
        m4aRecorder.start(1000);

        audioRecorderRef.current = recorderInstance;
        m4aRecorderRef.current = m4aRecorder;

        console.log('Audio recording setup complete');

    } catch (err) {
        console.error('Detailed error in recording setup:', {
            error: err,
            message: err.message,
            name: err.name
        });
        handleWarning('Error starting recording');
        cleanup();
        throw err;
    }
}, [handleWarning, cleanup]);


  // Audio sending
   // Optimized audio sending with retry logic
   const sendAudio = useCallback(async (retries = 3) => {
    if (!audioRecorderRef.current || !m4aRecorderRef.current) return null;
    if (isUploading) {
        console.log('Upload in progress, queueing request');
        uploadQueueRef.current.push(Date.now());
        return null;
    }

    const currentTime = Date.now();
    if (currentTime - lastUploadTimeRef.current < 1000) {
        console.log('Rate limiting in effect');
        return null;
    }

    try {
        setIsUploading(true);
        lastUploadTimeRef.current = currentTime;

        // Stop current recording
        m4aRecorderRef.current.stop();
        const { blob: wavBlob } = await audioRecorderRef.current.stop();

        // Start new recording immediately
        const stream = audioStreamRef.current;
        if (stream) {
            await startRecordingAudio(stream);
        }

        // Prepare upload
        const formData = new FormData();
        formData.append('audio_wav', wavBlob, 'audio.wav');
        formData.append('audio_m4a', m4aRecordingRef.current, 'audio.m4a');

        // Upload with retry logic
        for (let i = 0; i < retries; i++) {
            try {
                const response = await fetch(
                    `${URL}/upload_audio/${sessionDate.current}/${tempDirRef.current}?module_id=${selectedModule}`,
                    {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${user.accessToken}`,
                            'X-Retry-Attempt': i.toString()
                        },
                        body: formData
                    }
                );

                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                const result = await response.json();
                
                // Process queue if there are pending uploads
                if (uploadQueueRef.current.length > 0) {
                    uploadQueueRef.current = [];
                    console.log('Processed queued upload');
                }
                
                return result;
            } catch (error) {
                if (i === retries - 1) throw error;
                await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
            }
        }
    } catch (error) {
        console.error('Error processing audio:', error);
        handleWarning("Error processing audio");
        throw error;
    } finally {
        setIsUploading(false);
    }
}, [user.accessToken, handleWarning, startRecordingAudio, selectedModule, isUploading]);


  // Stop recording handler
  const stopRecording = useCallback(async () => {
    setRecording(false);
    cleanup();
    setResponseLoading(true);

    try {
      if (audioRecorderRef.current) {
        const { blob } = await audioRecorderRef.current.stop();
        const formData = new FormData();
        formData.append('audio', blob, 'audio.wav');

        await fetch(
          `${URL}/upload_audio/${sessionDate.current}/${tempDirRef.current}?module_id=${selectedModule}`,
          {
            headers: {
              'Authorization': `Bearer ${user.accessToken}`
            },
            method: 'POST',
            cache: 'no-cache',
            body: formData,
          }
        );
      }

      const overviewResponse = await fetch(
        `${URL}/overview_response/${user.uid}/${sessionDate.current}?module_id=${selectedModule}`,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.accessToken}`
          },
          timeout: 180000,
          body: JSON.stringify({
            session_duration: SESSION_TIMER - countdown,
            selected_topic: "Other",
            session_start_time: sessionDate.current,
            timestamp: new Date().toISOString()  // Add proper ISO timestamp
          })
        }
      );
    
      if (!overviewResponse.ok) {
        throw new Error("Overview response was not ok");
      }

      const overviewData = await overviewResponse.json();
      setOverviewData(overviewData);
      setSessionContextDate(getDateString());

      const currentSessionIdentifier = `${user.uid}-${getDateString()}`;
      sessionStorage.setItem(`overviewData-${currentSessionIdentifier}`, JSON.stringify(overviewData));

      const analysisResponse = await fetch(
        `${URL}/process_analysis/${user.uid}/${sessionDate.current}?module_id=${selectedModule}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.accessToken}`
          },
        }
      );

      const analysisData = await analysisResponse.json();
      setAnalysisData(analysisData);
      sessionStorage.setItem(`analysisData-${currentSessionIdentifier}`, JSON.stringify(analysisData));

      fetch(
        `${URL}/process_dbt/${user.uid}/${sessionDate.current}?module_id=${selectedModule}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.accessToken}`
          },
        }
      ).then(async (dbtResponse) => {
        const dbtData = await dbtResponse.json();
        setDbtData(dbtData);
        sessionStorage.setItem(`dbtData-${currentSessionIdentifier}`, JSON.stringify(dbtData));
      }).catch(console.error);

      navigate('/analysis', { 
        state: { 
          uniqueSessionID: sessionIDUnique.current,
          module_id: selectedModule 
        }
      });
    } catch (error) {
      console.error('Error during upload process:', error);
      setresultError(true);
    }
  }, [cleanup, countdown, SESSION_TIMER, user, navigate, setOverviewData, setSessionContextDate, setAnalysisData, setDbtData, selectedModule]);

// Start recording handler
const startRecording = useCallback(async (selectedModule) => {
  setOverviewData(null);
  if (resultError) setresultError(false);

  try {
      setLoading(true);

      // Get combined stream with both audio and video
      const combinedStream = await navigator.mediaDevices.getUserMedia({
          audio: {
              echoCancellation: true,
              noiseSuppression: true,
              autoGainControl: false,
              sampleRate: 22050,
              channelCount: 1
          },
          video: {
              width: 720,
              height: 720,
              facingMode: "user"
          }
      });

      console.log('Stream created:', {
          hasAudio: combinedStream.getAudioTracks().length > 0,
          hasVideo: combinedStream.getVideoTracks().length > 0,
          audioSettings: combinedStream.getAudioTracks()[0]?.getSettings()
      });

      audioStreamRef.current = combinedStream;

      if (camRef.current) {
          camRef.current.srcObject = combinedStream;
      }

      const session = await createSessionDir(sessionDate.current, user, selectedModule);
      if (!session.session_Dir_ID) {
          throw new Error('Failed to create session directory');
      }

      tempDirRef.current = session.session_Dir_ID;
      if (role === 'admin') {
          setQuestion(session.question);
          setScenario(session.scenario);
      }

      setLoading(false);
      setRecording(true);

      await startRecordingAudio(combinedStream);

      timeoutRef.current = setTimeout(() => {
          setRecording(false);
          stopRecording();
      }, SESSION_TIMER * 1000);

  } catch (err) {
      console.error('Stream setup error:', err);
      handleWarning('Error accessing camera or microphone');
      setLoading(false);
      setVideoAccess(false);
  }
}, [SESSION_TIMER, user, handleWarning, startRecordingAudio, stopRecording, selectedModule, resultError, role]);

  // Toggle webcam
  const toggleWebcam = useCallback(() => {
    setWebcamVisible(prev => !prev);
  }, []);

     // Optimized toggle questions with debounce
// In useMySessionLogic.js

// In useMySessionLogic.js
// In useMySessionLogic.js

const toggleQuestions = useCallback(async () => {
  if (isFirstQuestion) {
      setIsFirstQuestion(false);
      setCurrentQuestion(question);
      setShowQuestions(true);
      return;
  }

  setButtonDisabled(true);
  try {
      const audioData = await sendAudio();
      if (audioData?.transcription) {
          setShowQuestions(true);
          // Clear current question before starting new stream
          setCurrentQuestion('');
          try {
              await startStreaming(audioData.transcription);
          } catch (error) {
              console.error('Error in streaming:', error);
              handleWarning('Error processing response. Please try again.');
              // Reset states on error
              setShowQuestions(false);
              cleanupStreaming(); // Use the cleanup function from the hook
          }
      }
  } catch (error) {
      console.error('Error in toggleQuestions:', error);
      handleWarning('Error processing request');
  } finally {
      setButtonDisabled(false);
  }
}, [
  question,
  isFirstQuestion,
  sendAudio,
  startStreaming,
  handleWarning,
  cleanupStreaming // Add cleanupStreaming to dependencies
]);

  // Recording effect
  useEffect(() => {
    if (recording) {
      const timer = setTimeout(() => {
        setButtonDisabled(false);
      }, isFirstQuestion ? 5000 : 30000);

      return () => clearTimeout(timer);
    }
  }, [recording, isFirstQuestion]);

  // Set initial question
  useEffect(() => {
    if (scenario) {
      setCurrentQuestion(scenario);
    }
  }, [scenario]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      cleanup();
      window.history.replaceState({}, "");
    };
  }, [cleanup]);

  // Error cleanup
  useEffect(() => {
    if (resultError) {
      cleanup();
      setOverviewData(null);
    }
  }, [resultError, cleanup, setOverviewData]);

  return {
    selectedModule,
    setSelectedModule,
    loading,
    responseLoading,
    recording,
    startRecording,
    audioCanvasRef,
    audioRef,
    audioEndingRef,
    micCanvasRef,
    camRef,
    canvasParentRef,
    countdown,
    currentQuestion,
    buttonDisabled,
    showQuestions,
    toggleQuestions,
    isFirstQuestion,
    WarningMessage,
    webcamVisible,
    showAvatar,
    videoAccess,
    setVideoAccess,
    toggleWebcam,
    setShowAvatar,
    overviewData,
    stopRecording,
    resultError,
    handleScreenshots,
    loadVisualizer,
    handleWarning,
    isUploading,
    isStreaming,
    streamingText,
    cleanupStreaming, // Add this to the return object


  };
};