import { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useUser } from '../../../contexts/UserContext';
import { getDoc, doc, db } from '../../../Firebase';

// More reliable selectors based on your navigation structure
const NAVIGATION_SELECTORS = {
  home: 'a[href="/get-started"]',
  training: 'a[href="/session"]',
  insights: 'a[href="/mymood"]',
  blogs: 'a[href="/blogs"]',
  mainContent: '.home-slide'
};

const TOUR_STEPS = [
  {
    target: NAVIGATION_SELECTORS.home,
    title: "Welcome to Your Leadership Journey",
    content: "Your personalized path to leadership excellence begins here. Let's explore how Pokamind will support your growth.",
    placement: 'bottom',
    disableBeacon: true,
    floaterProps: {
      disableAnimation: true,
    }
  },
  {
    target: NAVIGATION_SELECTORS.training,
    title: "Personalized Training Programs",
    content: "Access curated training sessions designed to enhance your leadership capabilities and emotional intelligence.",
    placement: 'right'
  },
  {
    target: NAVIGATION_SELECTORS.insights,
    title: "Leadership Insights Dashboard",
    content: "Track your progress and gain valuable insights into your leadership development journey.",
    placement: 'right'
  },
  {
    target: NAVIGATION_SELECTORS.blogs,
    title: "Leadership Resources",
    content: "Stay informed with thought leadership content and expert insights from industry leaders.",
    placement: 'right'
  },
  {
    target: NAVIGATION_SELECTORS.mainContent,
    title: "Your Development Hub",
    content: "This is your personalized space for growth. Explore recommended content and track your leadership journey.",
    placement: 'top'
  }
];

// unique key to store completion status in localStorage with user ID prefix

const OnboardingTour = () => {
  const { user } = useUser();
  const [runTour, setRunTour] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const STORAGE_KEY = `onboarding_tour_completed_${user?.uid}`;

  useEffect(() => {
    const checkElements = () => {
      const elements = Object.values(NAVIGATION_SELECTORS).map(selector => 
        document.querySelector(selector)
      );
      return elements.every(el => !!el);
    };

    const initTour = async () => {
      if (!user) return;

      // Check if tour was completed before
      const hasCompletedTour = localStorage.getItem(STORAGE_KEY);
      if (hasCompletedTour) {
        console.log('Tour already completed');
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        const sessionCount = userData?.session_count?.total || 0;

        if (sessionCount === 0) {
          let attempts = 0;
          const maxAttempts = 10;
          
          const checkInterval = setInterval(() => {
            attempts++;
            console.log(`Checking for elements (attempt ${attempts})...`);
            
            if (checkElements()) {
              console.log('All elements found, starting tour...');
              clearInterval(checkInterval);
              setIsReady(true);
              setRunTour(true);
            } else if (attempts >= maxAttempts) {
              console.log('Max attempts reached, aborting tour');
              clearInterval(checkInterval);
            }
          }, 1000);
        }
      } catch (error) {
        console.error('Error checking first login:', error);
      }
    };

    initTour();

    // Cleanup
    return () => {
      setRunTour(false);
      setIsReady(false);
    };
  }, [user]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Save completion status to localStorage
      localStorage.setItem(STORAGE_KEY, 'true');
      setRunTour(false);
    }
  };

  if (!user || !isReady) return null;

  return (
    <Joyride
      steps={TOUR_STEPS}
      run={runTour}
      continuous
      callback={handleJoyrideCallback}
      showSkipButton
      showProgress
      disableOverlayClose
      spotlightClicks
      hideCloseButton
      floaterProps={{
        disableAnimation: true,
        styles: {
          arrow: {
            color: '#ffffff'
          }
        }
      }}
      locale={{
        back: 'Previous',
        close: 'Close',
        last: 'Ready to begin',
        next: 'Continue',
        skip: 'Skip tour'
      }}
      styles={{
        options: {
          arrowColor: '#ffffff',
          backgroundColor: '#ffffff',
          // Updated overlay color for better harmony with the background
          overlayColor: 'rgba(239, 235, 236, 0.15)',
          primaryColor: '#5C83BF',
          textColor: '#2D3748',
          // Improved spotlight shadow
          spotlightShadow: '0 0 15px rgba(222, 224, 231, 0.25)',
          width: 400,
          zIndex: 10000,
        },
        spotlight: {
            // Updated spotlight styles for better visual harmony
            backgroundColor: 'rgba(92, 131, 191, 0.15)',
            boxShadow: '0 0 0 2px rgba(92, 131, 191, 0.4), 0 0 0 4px rgba(239, 235, 236, 0.15)'
          },
        buttonNext: {
          backgroundColor: '#5C83BF',
          borderRadius: '24px',
          color: '#ffffff',
          padding: '14px 24px',
          fontSize: '15px',
          fontWeight: 600,
          border: 'none',
          boxShadow: '0 4px 6px rgba(92, 131, 191, 0.2)',
          transition: 'all 0.2s ease',
          '&:hover': {
            backgroundColor: '#4A69A8',
            transform: 'translateY(-1px)',
            boxShadow: '0 6px 8px rgba(92, 131, 191, 0.3)',
          }
        },
        buttonBack: {
          color: '#5C83BF',
          marginRight: 16,
          padding: '14px 24px',
          fontSize: '15px',
          fontWeight: 500,
          transition: 'all 0.2s ease',
          '&:hover': {
            color: '#4A69A8',
            transform: 'translateY(-1px)',
          }
        },
        buttonSkip: {
          color: '#718096',
          fontSize: '14px',
          fontWeight: 500,
          transition: 'color 0.2s ease',
          '&:hover': {
            color: '#4A5568'
          }
        },
        tooltipContainer: {
            borderRadius: '16px',
            backgroundColor: '#ffffff',
            boxShadow: '0 8px 24px rgba(222, 224, 231, 0.25)',
            padding: '24px'
          },
        tooltip: {
          fontSize: '15px',
          lineHeight: 1.6
        },
        tooltipTitle: {
          fontSize: '18px',
          fontWeight: 600,
          color: '#2D3748',
          marginBottom: '12px',
          lineHeight: 1.3
        },
        tooltipContent: {
          color: '#4A5568',
          padding: '0',
        },
        tooltipFooter: {
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '24px'
        },
        floater: {
          filter: 'drop-shadow(0 4px 12px rgba(0, 0, 0, 0.1))'
        }
      }}
    />
  );
};

export default OnboardingTour;