import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Typography,
  Alert,
  Tooltip,
  Fade,
  Skeleton,
  useTheme,
  CircularProgress
} from "@mui/material";
import {
  Add as AddIcon,
  ArrowBack,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  Person as PersonIcon,
  Refresh as RefreshIcon,
  CloudSync as CloudSyncIcon
} from "@mui/icons-material";
import { useUser } from "../../contexts/UserContext";
import { URL } from "../../Helpers";
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 24px rgba(0,0,0,0.06)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 6px 28px rgba(0,0,0,0.1)'
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  textTransform: 'none',
  fontWeight: 600,
  padding: theme.spacing(1, 2),
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1)
  }
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  borderRadius: theme.spacing(2)
}));

const SyncIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.text.secondary,
  fontSize: '0.875rem'
}));

// Constants
const STORAGE_KEYS = {
  USERS: 'company_users',
  LAST_UPDATED: 'users_last_updated',
  LAST_SYNC: 'users_last_sync'
};

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25];

const CompanyUsers = () => {
  const theme = useTheme();
  const { user: currentUser } = useUser();
  const navigate = useNavigate();

  // State Management
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    department: ''
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    user: null
  });
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [lastSync, setLastSync] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Session Storage Management
  const saveToSession = useCallback((userData) => {
    try {
      sessionStorage.setItem(STORAGE_KEYS.USERS, JSON.stringify(userData));
      const timestamp = new Date().toISOString();
      sessionStorage.setItem(STORAGE_KEYS.LAST_UPDATED, timestamp);
      sessionStorage.setItem(STORAGE_KEYS.LAST_SYNC, timestamp);
      setLastSync(timestamp);
    } catch (error) {
      console.error('Error saving to session storage:', error);
    }
  }, []);

  const getFromSession = useCallback(() => {
    try {
      const sessionData = sessionStorage.getItem(STORAGE_KEYS.USERS);
      const lastSyncTime = sessionStorage.getItem(STORAGE_KEYS.LAST_SYNC);
      if (lastSyncTime) {
        setLastSync(lastSyncTime);
      }
      return sessionData ? JSON.parse(sessionData) : null;
    } catch (error) {
      console.error('Error reading from session storage:', error);
      return null;
    }
  }, []);

  const clearSession = useCallback(() => {
    try {
      Object.values(STORAGE_KEYS).forEach(key => {
        sessionStorage.removeItem(key);
      });
      setLastSync(null);
    } catch (error) {
      console.error('Error clearing session storage:', error);
    }
  }, []);

  // Data Fetching
  const fetchUsers = useCallback(async (forceRefresh = false) => {
    try {
      if (!forceRefresh) {
        setLoading(true);
        const sessionData = getFromSession();
        if (sessionData) {
          setUsers(sessionData);
          setLoading(false);
          return;
        }
      }

      const response = await fetch(`${URL}/get_users_sessions_by_company`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`
        }
      });
      
      const data = await response.json();
      if (response.ok) {
        setUsers(data.users_list);
        saveToSession(data.users_list);
      } else {
        throw new Error(data.message || 'Failed to fetch users');
      }
    } catch (error) {
      showAlert(error.message || 'Error connecting to server', 'error');
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }, [currentUser.accessToken, saveToSession, getFromSession]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Form Handlers
  const handleAddUser = async (e) => {
    e.preventDefault();
    setFormSubmitting(true);
    try {
      const response = await fetch(`${URL}/create_user_for_company`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser.accessToken}`
        },
        body: JSON.stringify(formData)
      });
      
      const data = await response.json();
      if (response.ok) {
        const newUser = { ...formData, id: data.userId };
        const updatedUsers = [...users, newUser];
        setUsers(updatedUsers);
        saveToSession(updatedUsers);
        setFormData({ name: '', email: '', department: '' });
        showAlert('User added successfully', 'success');
      } else {
        throw new Error(data.message || 'Error creating user');
      }
    } catch (error) {
      showAlert(error.message || 'Network error occurred', 'error');
    } finally {
      setFormSubmitting(false);
    }
  };

  const handleUpdateUser = async () => {
    if (!editingUser) return;
    
    try {
      const response = await fetch(`${URL}/update_company_user/${editingUser.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser.accessToken}`
        },
        body: JSON.stringify(editingUser)
      });

      if (response.ok) {
        const updatedUsers = users.map(user => 
          user.id === editingUser.id ? editingUser : user
        );
        setUsers(updatedUsers);
        saveToSession(updatedUsers);
        setEditingUser(null);
        showAlert('User updated successfully', 'success');
      } else {
        const data = await response.json();
        throw new Error(data.message || 'Error updating user');
      }
    } catch (error) {
      showAlert(error.message || 'Network error occurred', 'error');
    }
  };

  const handleDeleteUser = async () => {
    if (!deleteDialog.user) return;
    
    try {
      const response = await fetch(`${URL}/delete_company_user/${deleteDialog.user.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`
        }
      });

      if (response.ok) {
        const updatedUsers = users.filter(user => user.id !== deleteDialog.user.id);
        setUsers(updatedUsers);
        saveToSession(updatedUsers);
        showAlert('User deleted successfully', 'success');
      } else {
        const data = await response.json();
        throw new Error(data.message || 'Error deleting user');
      }
    } catch (error) {
      showAlert(error.message || 'Network error occurred', 'error');
    } finally {
      setDeleteDialog({ open: false, user: null });
    }
  };

  // UI Handlers
  const handleRefreshData = async () => {
    setRefreshing(true);
    clearSession();
    await fetchUsers(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showAlert = (message, severity) => {
    setAlert({
      open: true,
      message,
      severity
    });
  };

  // Memoized Values
  const paginatedUsers = useMemo(() => {
    const startIndex = page * rowsPerPage;
    return users.slice(startIndex, startIndex + rowsPerPage);
  }, [users, page, rowsPerPage]);

  const lastSyncFormatted = useMemo(() => {
    if (!lastSync) return 'Never';
    const date = new Date(lastSync);
    return new Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short'
    }).format(date);
  }, [lastSync]);

  // Loading Skeletons
  const renderSkeletonRows = () => (
    Array(rowsPerPage).fill(0).map((_, index) => (
      <TableRow key={index}>
        <TableCell><Skeleton animation="wave" /></TableCell>
        <TableCell><Skeleton animation="wave" /></TableCell>
        <TableCell><Skeleton animation="wave" /></TableCell>
        <TableCell align="right"><Skeleton animation="wave" width={100} /></TableCell>
      </TableRow>
    ))
  );

  return (
    <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
      {/* Header */}
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Return to Dashboard">
            <IconButton
              onClick={() => navigate("/get-started")}
              sx={{ mr: 2 }}
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Typography variant="h5" component="h1" fontWeight="600">
            Team Management
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <SyncIndicator>
            <CloudSyncIcon fontSize="small" />
            Last synced: {lastSyncFormatted}
          </SyncIndicator>
          <Tooltip title="Refresh Data from Server">
            <ActionButton
              variant="outlined"
              onClick={handleRefreshData}
              disabled={refreshing}
              startIcon={refreshing ? <CircularProgress size={20} /> : <RefreshIcon />}
            >
              {refreshing ? 'Refreshing...' : 'Refresh'}
            </ActionButton>
          </Tooltip>
        </Box>
      </Box>

      <Grid container spacing={4}>
        {/* Add User Form */}
        <Grid item xs={12} md={4}>
          <StyledCard>
            <CardHeader
              title={
                <Box display="flex" alignItems="center">
                  <PersonIcon sx={{ mr: 1 }} />
                  <Typography variant="h6">Add Team Member</Typography>
                </Box>
              }
              sx={{
                bgcolor: theme.palette.primary.main,
                color: 'white',
                '& .MuiCardHeader-content': { flex: 1 }
              }}
            />
            <Divider />
            <CardContent>
              <Box
                component="form"
                onSubmit={handleAddUser}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2.5,
                  position: 'relative'
                }}
              >
                {formSubmitting && (
                  <LoadingOverlay>
                    <CircularProgress />
                  </LoadingOverlay>
                )}
                <TextField
                  label="Full Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled={formSubmitting}
                />
                <TextField
                  label="Email Address"
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled={formSubmitting}
                />
                <TextField
                  label="Department"
                  value={formData.department}
                  onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled={formSubmitting}
                />
                <ActionButton
                  type="submit"
                  variant="contained"
                  startIcon={<AddIcon />}
                  disabled={formSubmitting}
                  sx={{ mt: 1 }}
                >
                  Add Member
                </ActionButton>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Users Table */}
        <Grid item xs={12} md={8}>
          <StyledCard>
            <CardHeader
              title={
<Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="h6">
                    Team Members ({users.length})
                  </Typography>
                </Box>
              }
              sx={{
                bgcolor: theme.palette.primary.main,
                color: 'white'
              }}
            />
            <Divider />
            <CardContent sx={{ p: 0, flexGrow: 1, position: 'relative' }}>
              {loading && (
                <LoadingOverlay>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <CircularProgress />
                    <Typography variant="body2" color="text.secondary">
                      Loading team members...
                    </Typography>
                  </Box>
                </LoadingOverlay>
              )}
              <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>Department</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 600 }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      renderSkeletonRows()
                    ) : (
                      paginatedUsers.map((user) => (
                        <TableRow
                          key={user.id}
                          hover
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            transition: 'background-color 0.2s ease'
                          }}
                        >
                          <TableCell>
                            {editingUser?.id === user.id ? (
                              <TextField
                                value={editingUser.name}
                                onChange={(e) => setEditingUser({
                                  ...editingUser,
                                  name: e.target.value
                                })}
                                variant="standard"
                                fullWidth
                                error={!editingUser.name}
                                helperText={!editingUser.name && "Name is required"}
                              />
                            ) : (
                              user.name
                            )}
                          </TableCell>
                          <TableCell>
                            {editingUser?.id === user.id ? (
                              <TextField
                                value={editingUser.email}
                                onChange={(e) => setEditingUser({
                                  ...editingUser,
                                  email: e.target.value
                                })}
                                variant="standard"
                                fullWidth
                                type="email"
                                error={!editingUser.email}
                                helperText={!editingUser.email && "Valid email is required"}
                              />
                            ) : (
                              user.email
                            )}
                          </TableCell>
                          <TableCell>
                            {editingUser?.id === user.id ? (
                              <TextField
                                value={editingUser.department}
                                onChange={(e) => setEditingUser({
                                  ...editingUser,
                                  department: e.target.value
                                })}
                                variant="standard"
                                fullWidth
                                error={!editingUser.department}
                                helperText={!editingUser.department && "Department is required"}
                              />
                            ) : (
                              user.department
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                              {editingUser?.id === user.id ? (
                                <>
                                  <Tooltip title="Save Changes">
                                    <IconButton
                                      color="primary"
                                      onClick={handleUpdateUser}
                                      size="small"
                                      disabled={!editingUser.name || !editingUser.email || !editingUser.department}
                                    >
                                      <SaveIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Cancel">
                                    <IconButton
                                      color="error"
                                      onClick={() => setEditingUser(null)}
                                      size="small"
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ) : (
                                <>
                                  <Tooltip title="Edit User">
                                    <IconButton
                                      color="primary"
                                      onClick={() => setEditingUser({ ...user })}
                                      size="small"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Delete User">
                                    <IconButton
                                      color="error"
                                      onClick={() => setDeleteDialog({
                                        open: true,
                                        user
                                      })}
                                      size="small"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                    {!loading && users.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={4} align="center" sx={{ py: 8 }}>
                          <Typography variant="body1" color="text.secondary">
                            No team members found. Add your first team member using the form.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={users.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              />
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={() => setDeleteDialog({ open: false, user: null })}
        TransitionComponent={Fade}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          Confirm User Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete <strong>{deleteDialog.user?.name}</strong>? 
            This action cannot be undone and will remove all associated data.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button
            onClick={() => setDeleteDialog({ open: false, user: null })}
            color="inherit"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteUser}
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
          >
            Delete User
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Snackbar */}
      <Alert
        open={alert.open}
        autoHideDuration={4000}
        onClose={() => setAlert({ ...alert, open: false })}
        severity={alert.severity}
        sx={{
          position: 'fixed',
          bottom: theme.spacing(3),
          right: theme.spacing(3),
          zIndex: theme.zIndex.snackbar
        }}
      >
        {alert.message}
      </Alert>
    </Box>
  );
};

export default CompanyUsers;