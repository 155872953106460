import { URL } from "../../../Helpers";

// Configuration for visualization and sessions
const CONFIG = {
  VISUALIZER: {
    FFT_SIZE: 1024,
    SMOOTHING: 0.8,
    CIRCLE_COUNT: 3,
    COLORS: {
      AUDIO: {
        PRIMARY: [76, 110, 245],
        OPACITY: [0.9, 0.6, 0.3]
      },
      STREAM: {
        PRIMARY: [255, 161, 84],
        OPACITY: [0.9, 0.6, 0.3]
      }
    }
  },
  SESSIONS: {
    FREE: {
      weeklyLimit: 1,
      dailyLimit: true,
      SESSION_TIMER: 180
    },
    PREMIUM: {
      weeklyLimit: 12,
      dailyLimit: false,
      durationToStart: 180,
      dailyDurationLimit: 600,
      SESSION_TIMER: 600
    }
  }
};

// Export session limits
export const tierLimits = {
  free: CONFIG.SESSIONS.FREE,
  premium: CONFIG.SESSIONS.PREMIUM
};

// Time formatter with simple cache
const timeCache = new Map();

export const formatTime = (seconds) => {
  if (!seconds && seconds !== 0) return "00:00";
  
  const cacheKey = Math.floor(seconds);
  if (timeCache.has(cacheKey)) return timeCache.get(cacheKey);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedTime = String(minutes).padStart(2, "0") + ":" + 
                       String(remainingSeconds).padStart(2, "0");
  
  timeCache.set(cacheKey, formattedTime);
  if (timeCache.size > 3600) timeCache.clear();
  
  return formattedTime;
};

// Visualizer with stable audio handling
export const startVisualizer = (canvas, audiofile = null, audioStream = null) => {
  if (!canvas) return null;

  const setCanvasSize = () => {
    const dpr = window.devicePixelRatio || 1;
    const rect = canvas.getBoundingClientRect();
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;
    ctx.scale(dpr, dpr);
  };

  const ctx = canvas.getContext('2d');
  setCanvasSize();

  const resizeHandler = () => {
    setCanvasSize();
    baseRadius = Math.min(canvas.width, canvas.height) * 0.15;
  };
  window.addEventListener('resize', resizeHandler);

  // Audio setup with error handling
  let audioContext;
  try {
    audioContext = new (window.AudioContext || window.webkitAudioContext)();
  } catch (error) {
    console.error('Failed to create audio context:', error);
    return null;
  }

  const analyser = audioContext.createAnalyser();
  analyser.fftSize = CONFIG.VISUALIZER.FFT_SIZE;
  analyser.smoothingTimeConstant = CONFIG.VISUALIZER.SMOOTHING;

  let audioSource;
  try {
    audioSource = audiofile
      ? audioContext.createMediaElementSource(audiofile)
      : audioContext.createMediaStreamSource(audioStream);

    audioSource.connect(analyser);
    if (audiofile) {
      analyser.connect(audioContext.destination);
      audiofile.play().catch(console.error);
    }
  } catch (error) {
    console.error('Failed to set up audio source:', error);
    audioContext.close();
    return null;
  }

  const frequencyData = new Uint8Array(analyser.frequencyBinCount);
  let baseRadius = Math.min(canvas.width, canvas.height) * 0.15;
  const colors = audiofile ? CONFIG.VISUALIZER.COLORS.AUDIO : CONFIG.VISUALIZER.COLORS.STREAM;

  const draw = () => {
    analyser.getByteFrequencyData(frequencyData);
    
    const average = frequencyData.reduce((a, b) => a + b, 0) / frequencyData.length;
    const scale = average / 256;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    for (let i = 0; i < CONFIG.VISUALIZER.CIRCLE_COUNT; i++) {
      const radius = baseRadius * (1 + (i * 0.5)) * (1 + scale * 0.3);
      const opacity = colors.OPACITY[i];

      ctx.beginPath();
      ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
      ctx.strokeStyle = 'rgba(' + colors.PRIMARY.join(',') + ',' + opacity + ')';
      ctx.lineWidth = 2;
      ctx.stroke();
    }

    requestAnimationFrame(draw);
  };

  const animation = requestAnimationFrame(draw);

  return () => {
    window.removeEventListener('resize', resizeHandler);
    cancelAnimationFrame(animation);
    if (audioSource) audioSource.disconnect();
    if (analyser) analyser.disconnect();
    if (audioContext) audioContext.close();
  };
};

// Network utilities with reliable error handling
export const createSessionDir = async (sessionDate, user, module = null) => {
  if (!sessionDate || !user?.accessToken) {
    console.error('Invalid parameters for createSessionDir');
    return null;
  }

  try {
    const response = await fetch(
      `${URL}/session_dir/${sessionDate}${module !== null ? `?module_id=${module}` : ''}`,
      {
        headers: {
          'Authorization': `Bearer ${user.accessToken}`
        },
        method: 'GET',
        cache: 'no-cache'
      }
    );

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || 'Network response was not ok');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating session directory:', error);
    return null;
  }
};

// Data upload with proper error handling
export const uploadData = async (data, url, accessToken) => {
  if (!data || !url || !accessToken) {
    throw new Error('Missing required parameters for upload');
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: typeof data === 'string' ? data : JSON.stringify(data)
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || 'Upload failed');
    }

    return await response.json();
  } catch (error) {
    console.error('Upload error:', error);
    throw error;
  }
};

// Warning handler with cleanup
let warningTimeout;
export const handleWarning = (message, setShowWarning, setWarningMessage) => {
  if (warningTimeout) {
    clearTimeout(warningTimeout);
    warningTimeout = null;
  }
  
  setShowWarning(true);
  setWarningMessage(message);

  warningTimeout = setTimeout(() => {
    setShowWarning(false);
    setWarningMessage(null);
  }, 1500);
};

// Audio upload with proper FormData handling
export const uploadAudioBlob = async (blob, sessionDate, tempDirId, accessToken) => {
  if (!blob || !sessionDate || !tempDirId || !accessToken) {
    throw new Error('Missing required parameters for audio upload');
  }

  const formData = new FormData();
  formData.append('audio', blob, 'audio.wav');

  try {
    const response = await fetch(
      `${URL}/upload_audio/${sessionDate}/${tempDirId}`,
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formData
      }
    );

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || 'Upload failed');
    }

    return await response.json();
  } catch (error) {
    console.error('Audio upload error:', error);
    throw error;
  }
};